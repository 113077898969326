// Including: Gradient text, Dropcap, Highlight, List, divider/space, background

// Gradient text
.gradient-text {
  font-size: 150px;
  font-weight: 300;
  color: $brand-primary; // fallback
  background: -webkit-linear-gradient(92deg, #fb83fa, #00aced);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-small {
  font-size: 12px;
}

.text-normal {
  font-size: $font-size-base;
}

.text-huge {
  font-size: 72px;
}

.text-large {
  font-size: 50px;
}

.text-thin {
  font-weight: 300;
}

.size-h1 {
  font-size: $font-size-h1;
}

.size-h2 {
  font-size: $font-size-h2;
}

.size-h3 {
  font-size: $font-size-h3;
}

.size-h4 {
  font-size: $font-size-h4;
}

.size-h5 {
  font-size: $font-size-h5;
}

.size-h6 {
  font-size: $font-size-h6;
}

.size-h7 {
  font-size: $font-size-h7;
}

.size-h8 {
  font-size: $font-size-h8;
}

.text-ultralight {
  font-weight: 100;
}

.bold {
  font-weight: $headings-font-weight;
}

.color-primary,
a.color-primary {
  color: $brand-primary;

  &:hover {
    color: $brand-primary;
  }
}

.color-success,
a.color-success {
  color: $brand-success;

  &:hover {
    color: $brand-success;
  }
}

.color-info,
a.color-info {
  color: $brand-info;

  &:hover {
    color: $brand-info;
  }
}

.color-warning,
a.color-warning {
  color: $brand-warning;

  &:hover {
    color: $brand-warning;
  }
}

.color-danger,
a.color-danger {
  color: $brand-danger;

  &:hover {
    color: $brand-danger;
  }
}

.color-dark,
a.color-dark {
  color: $dark;

  &:hover {
    color: $dark;
  }
}

.color-white,
a.color-white {
  color: $white;

  &:hover {
    color: $white;
  }
}

.color-gray-dark,
a.color-gray-dark {
  color: $gray-dark;

  &:hover {
    color: $gray-dark;
  }
}

.color-gray,
a.color-gray {
  color: $gray;

  &:hover {
    color: $gray;
  }
}

.color-gray-light,
a.color-gray-light {
  color: $gray-light;

  &:hover {
    color: $gray-light;
  }
}

.color-gray-lighter,
a.color-gray-lighter {
  color: $gray-lighter;

  &:hover {
    color: $gray-lighter;
  }
}


// Dropcap
.dropcap,
.dropcap-square,
.dropcap-circle {
  display: block;
  float: left;
  font-weight: normal;
  margin-right: .25em;
  text-shadow: none;
}

.dropcap {
  font-size: 2em;
}

.dropcap-square,
.dropcap-circle {
  background-color: $gray-lighter;
  color: $body-color;
  width: 36px;
  text-align: center;
}

.dropcap-square {
  border-radius: $border-radius;
  font-size: 2.3em;
}

.dropcap-circle {
  border-radius: 50%;
  font-size: 1.78em;
}

.dropcap.colored {
  color: $brand-primary;
}

.dropcap-square.colored,
.dropcap-circle.colored {
  background-color: $brand-primary;
  color: $white;
}

// Hightlight
.ui-highlight {
  background-color: $dark;
  color: $white;
  border-radius: $border-radius;
  padding: 2px 5px;

  &.colored {
    background-color: $brand-primary;
  }
}
