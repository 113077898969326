.card-box {
  min-height: 120px;
  padding: 12px 0px 0px 8px;
  background-color: $card-bg;
  border-radius: $border-radius;
  margin: $border-radius;

  p {
    margin: 0;
  }

  .box-icon {
    display: block;
    width: 70px;
    height: 70px;
    line-height: 70px;
    vertical-align: middle;

    &.rounded {
      border-radius: 50%;
    }
  }

  .btn-icon,
  .btn-icon-lined {
    margin: 2px 6px 0px 0px;
    font-size: 2rem;
  }

  .box-info {
    display: block;
    vertical-align: top;
    width: 75%;
  }

  .box-num {
    font-size: 1.25rem;
    line-height: 1;
    margin: 18px 0px 12px 0px;
  }
}
