.app-sidebar {

  .ant-menu-submenu {
    background: $twm-blue;
  }

  .ant-menu-sub {
    overflow: hidden;
    padding-bottom: 10px;

    .ant-menu-item {
      margin-bottom: -6px !important;
      overflow: visible;
      background: #20292e;
    }
  }

  .app-sidebar-button-area {
    background: rgb(50, 53, 54);
    text-align: center;
  }

  .top-section {
    background: black;
  }

  .ant-input-search {
    height: 2rem;
    padding-top: .25rem;
    margin-bottom: .5rem;

    input {
      border-radius: 0;
    }

    .ant-input-search-icon {
      color: #fff;
    }
  }

  .ant-input {
    font-size: .75rem;
    color: #f2f2f2;
    background: #4d545d;
    border: none;
  }

  .ant-input-suffix {
    position: relative;
    right: .25rem;
    top: auto;

    bottom: 1rem;
    float: right;
  }

  .ant-input-search-icon {
    margin-right: 0;
  }

  .ant-menu-item {
    padding-left: 1rem !important;
  }

  .sidebar-header {
    padding: 0 0 0 19px;
    line-height: $l_header_height;
    transition: $l_transition_antd;
  }

  .brand {
    font-size: 20px;
    margin-left: 13px;
  }

  .support-logo-img {
    height: 64px;
    width: 64px;
    margin-bottom: -4px;
  }

  &.ant-layout-sider {
    background: #323536;
  }

  &.ant-layout-sider-collapsed {
    .sidebar-header {
      padding-left: 19px;
    }

    .logo-img {
      margin-bottom: -6px;
    }

    .brand {
      display: none;
    }
  }

  .sidebar-footer {
    background-color: rgba(0, 0, 0, .035);
    // same with antd menu item
    > a {
      display: block;
      padding: 0 16px 0 24px;
      line-height: $sidebar_footer_height;

      &:focus {
        text-decoration: none;
      }

      .anticon {
        margin-right: 8px;
      }
    }
  }
}
