@import "../themes/variables-theme";

$box_border_color: rgba(0, 0, 0, .1);

.box {
  position: relative;
  margin-bottom: 1.5rem;
  border: 0px solid $box_border_color;
  border-radius: $border-radius;

  &.box-transparent {
    border: 0;
  }

  .box-dark {
    background-color: rgba(0, 0, 0, .03);
  }
}

// put color in .box-default, so .bg-xxx can overrides box color easily
.box-default {
  color: $text-muted;
  background-color: $card-bg;
}

.wallet-mid-section-height.ant-table-wrapper {
  height: 15.5rem !important;

}

.box-header,
.box-heading {
  font-size: $font-size-base;
  padding: $card-spacer-y $card-spacer-x;
  border-bottom: 1px solid transparent;
  border-radius: ($border-radius - 1) ($border-radius - 1) 0 0;
  font-weight: $headings-font-weight;
}

.box-toolbar {
  position: absolute;
  top: $card-spacer-x;
  right: $card-spacer-x;

  a {
    color: $text-muted;
  }
}

.box-divider {
  height: 1px;
  background: $box_border_color;
  margin: 0 $card-spacer-x;
}

.box-body {
  padding: $card-spacer-x;
  @include clearfix;

  &.padding-lg {
    padding: 30px 15px;
  }

  &.padding-xl {
    padding: 60px 15px;
  }

  &.padding-lg-h {
    padding: 15px 30px;
  }

  &.padding-lg-v {
    padding: 30px 15px;
  }
}

// Theme
// --------------------------------------------------
.theme-gray {
  .box-default {
    background-color: $theme_gray_box_bg;
  }
}

.theme-dark {
  .box-default {
    background-color: $theme_dark_box_bg;
  }
}
