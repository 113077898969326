// Desktop only, for boxed layout
#app-main {
  max-width: 100%;
  margin: auto;
  transition: max-width .35s ease;
}

@media only screen and (min-width: $l_screen_md_min) {
  .boxed-layout {
    &#app-main {
      max-width: $l_boxed_layout_max_width;
      background-color: darken($body-bg, 5%);
    }
  }
  .ant-layout {
    background-color: darken($body-bg, 5%);
  }
}
