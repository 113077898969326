.page-dashboard {

  padding-top: 1em;
  padding-bottom: 2em;

  .card-box {
    .bg-color-warning {
      color: #ffffff;
      background-color: $brand-warning;
    }

    margin-bottom: 1.5rem;
  }

  .data-table {
    border-radius: $border-radius-lg;
    background-color: #ffffff;
  }

}
