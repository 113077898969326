//
@mixin divider() {
  display: block;
  border: 0;
  border-top: 1px solid transparent;
  &.divider-solid {
    border-style: solid;
    border-color: $state-default-border;
  }
  &.divider-dashed {
    border-style: dashed;
    border-color: $state-default-border;
  }
  &.divider-dotted {
    border-style: dotted;
    border-color: $state-default-border;
  }
}

.divider {
  @include divider();
  margin-top: 10px;
  margin-bottom: 10px;
}

.divider-xs {
  margin-top: 3px;
  margin-bottom: 3px;
}

.divider-sm {
  margin-top: 5px;
  margin-bottom: 5px;
}

.divider-md {
  margin-top: 15px;
  margin-bottom: 15px;
}

.divider-lg {
  margin-top: 20px;
  margin-bottom: 20px;
}

.divider-xl {
  margin-top: 30px;
  margin-bottom: 30px;
}

.divider-xxl {
  margin-top: 50px;
  margin-bottom: 50px;
}


.space {
  display: inline;
  padding: 6px;
}

.space-md {
  padding: 15px;
}

.space-lg {
  padding: 25px;
}


span.block {
  display: block;
}

span.hidden {
  display: none;
}

.no-margin {
  margin: 0 !important;
}

.no-margin-h {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.no-margin-left {
  margin-left: 0 !important;
}

.no-margin-right {
  margin-right: 0 !important;
}

.no-border {
  border: 0 !important;
}

.no-border-radius {
  border-radius: 0;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-h {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.no-shadow {
  box-shadow: 0;
}

.full-width {
  width: 100%;
}

.pull-in {
  margin-left: -15px;
  margin-right: -15px;
}

.margin-bottom-xs {
  margin-bottom: 4px;
}

.margin-bottom-sm {
  margin-bottom: 8px;
}

.margin-bottom-md {
  margin-bottom: 12px;
}

.margin-bottom-lg {
  margin-bottom: 16px;
}

.margin-bottom-xlg {
  margin-bottom: 24px;
}

.margin-top-xs {
  margin-top: 4px;
}

.margin-top-sm {
  margin-top: 8px;
}

.margin-top-md {
  margin-top: 12px;
}

.margin-top-lg {
  margin-top: 16px;
}

.margin-top-xlg {
  margin-top: 24px;
}

.margin-left-xs {
  margin-left: 4px;
}

.margin-left-sm {
  margin-left: 8px;
}

.margin-left-md {
  margin-left: 12px;
}

.margin-left-lg {
  margin-left: 16px;
}

.margin-left-xlg {
  margin-left: 24px;
}

.margin-right-xs {
  margin-right: 4px;
}

.margin-right-sm {
  margin-right: 8px;
}

.margin-right-md {
  margin-right: 12px;
}

.margin-right-lg {
  margin-right: 16px;
}

.margin-right-xlg {
  margin-right: 24px;
}

.margin-right-sm {
  margin-right: 16px;
}

.margin-right-md {
  margin-right: 24px;
}

.margin-right-lg {
  margin-right: 32px;
}

.margin-right-xlg {
  margin-right: 48px;
}

.margin-left-sm {
  margin-left: 16px;
}

.margin-left-md {
  margin-left: 24px;
}

.margin-left-lg {
  margin-left: 32px;
}

.margin-left-xlg {
  margin-left: 48px;
}

.margin-top-sm {
  margin-top: 16px;
}

.margin-top-md {
  margin-top: 24px;
}

.margin-top-lg {
  margin-top: 32px;
}

.margin-top-xlg {
  margin-top: 48px;
}

.ant-popover-inner-content {
  height: 84px;
  padding: 16px 24px;
}

.wallet-icon {
  color: $gray-light;
}
