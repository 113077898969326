$font-size-small: 12px;

.body-auth {
  background: #ECE9E6;
  background: -webkit-linear-gradient(to bottom, #FFFFFF, #ECE9E6);
  background: linear-gradient(to bottom, #FFFFFF, #ECE9E6);
}

.page-auth,
.page-login {
  .main-body {
    width: 100%;
    max-width: 420px;
  }
}

.page-auth,
.page-login,
.page-signup {
  padding: 60px;
  height: 100vh !important;
  background-image: url(./bg-login.jpg);
  background-size: cover;

  @media(max-width: 768px) {
    padding: 10px;
  }

  .main-body {
    margin: 0 auto;
    padding-top: 50px;
    @media (min-width: map-get($grid-breakpoints, md)) {
      padding-top: 150px;
    }
  }

  .body-inner {
    position: relative;
    padding: 15px 20px;

    &.no-additional-info {
      padding: 30px 20px;
    }

    &:before {
      z-index: 0;
      content: ' ';
      line-height: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $brand-primary;
      height: 200px;
      border-radius: 0 0 $border-radius-lg $border-radius-lg;
    }

    .box {
      margin-bottom: 0;
    }
  }

  .submit-button {
    width: 100%;
  }

  h1 {
    font-weight: normal;
    color: $brand-primary;
    font-size: 34px;
    margin-bottom: 40px;

    a {
      color: $brand-primary;
    }
  }

  .wrapper {
    margin-top: 50px;
    @media (min-width: map-get($grid-breakpoints, md)) {
      margin-top: 150px;
    }
  }

  .logo {
    width: 50%;
    font-size: 26px;
    font-weight: normal;

    a {
      &:hover {
        text-decoration: none;
      }
    }
  }

  .tradewind-logo {
    padding: 25px 35px 40px;
  }

  .forgot-password-link {
    position: relative;
    padding-bottom: 40px;
    margin-top: -20px;

    a {
      position: absolute;
      font-size: 14px;
      right: 0;
      top: 0;
    }
  }
}
