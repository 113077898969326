table {
  padding: 8px;
  font-size: 10px
}

.ant-table {
  margin-bottom: 1rem !important;
  font-size: $font-size-sm;
}

td.padded-column-right,
td.padded-column-right {
  text-align: right;
  padding-right: 1rem !important;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
  padding: 12px 0px 12px 0px;
  word-break: break-all;
}

.table-operations {
  float: right;
}

.table-operations > button {
  margin-right: 2px;
}

th.column-right,
td.column-right {
  text-align: right !important;
}

th.column-center,
td.column-center {
  text-align: center !important;
}

.negative {
  color: red;
}

.dropdown-menu {
  width: auto;
  padding-top: 8px;

  border-radius: 6px;
  background: #fff;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .2);


}

.dropdown-input {
  display: block;
  width: auto;
  margin-left: 8px;
  margin-right: 8px;
}

.dropdown-checkbox {
  display: block;
  width: auto;
  margin-left: 8px;
  margin-top: 4px;
  padding-bottom: 4px;

}
