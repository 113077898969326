@import "bootstrap";

@import "vendors/antd/antd";
@import "vendors/rc-queue-anim";

@import "layout/variables-layout";

@import "themes/variables-theme";
@import "ui/variables";
@import "ui";

@import "pages/dashboard";
@import "pages/error";
@import "../../lib/auth/styles/auth.scss";
@import "pages/version";
