.page-version {

  .hero {
    &.hero-bg-img {
      background-image: url('https://storage.googleapis.com/internal-feedback-bucket/assets/code.jpg');
      background-size: cover;
      padding: 0;
      background-position: center center;
    }

    .hero-inner {
      background-color: rgba(#000, .15);
      padding: 90px 0;
    }

    .hero-title,
    .hero-tagline {
      color: #fff;
    }
  }

  .stat-container {
    margin-bottom: 30px;

    .stat-item {
      margin-bottom: 20px;
      border-bottom: 2px solid $page-bg;
    }

    .stat-num {
      display: block;
      color: $brand-primary;
      font-size: 36px;
      font-weight: $font-weight-thin;
      line-height: 36px;
    }

    .stat-desc {
      display: inline-block;
      margin-bottom: -2px;
      padding-bottom: 20px;
      border-bottom: 2px solid $brand-primary;
      font-size: 20px;
      line-height: 22px;
      font-weight: $headings-font-weight;
    }

    .space-bar {
      padding: 2px;
      border-radius: $border-radius;
      margin-right: 6px;
    }
  }

  .article {
    &:nth-of-type(1) {
      padding-top: 75px;
    }
  }

  .space-bar {
    padding: 2px;
    border-radius: $border-radius;
    margin-right: 6px;
  }

}

.stat-container {
  margin-top: 1.5rem;
}

.stat-item {
  margin-bottom: 20px;
  border-bottom: 2px solid $page-bg;
}

.stat-num {
  display: block;
  color: $brand-primary;
  font-size: 36px;
  font-weight: $font-weight-thin;
  line-height: 36px;
}

.stat-desc {
  display: inline-block;
  margin-bottom: -2px;
  padding-bottom: 20px;
  border-bottom: 2px solid $brand-primary;
  font-size: 20px;
  line-height: 22px;
  font-weight: $headings-font-weight;
}

.space-bar {
  padding: 2px;
  border-radius: $border-radius;
  margin-right: 6px;
}
