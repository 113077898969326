
.ui-animate {
  > div {
    > .article {
      .article-title {
        margin: 16px 0 16px;
        border-top: 1px solid rgba(0, 0, 0, 0.117647);
      }
    }
  }

  > div:nth-of-type(1) {
    > .article {
      .article-title {
        margin: 0 0 16px;
        border-top: 0;
      }
    }
  }
}
