.app-header {
  // no border, otherwise other elements' height can only be ($l_header_height -1)
  border: 0;
  padding: 0;

  &.ant-layout-header {
    height: $l_header_height;
    line-height: $l_header_height;
  }

  .app-header-inner {
    height: $l_header_height;
  }
}

.red-banner {
  background: #FA8072 !important;
}

.app-header-inner {
  display: flex;
  justify-content: space-between;
  transition: $l_transition_antd;

  .header-left {

    .ant-menu {
      min-width: 600px;
      text-align: center;
    }

    .ant-menu {
      min-width: 600px;
      text-align: center;
    }

    .ant-menu-item {
      margin-bottom: 0 !important;

      a.app-nav {
        box-shadow: none;
        height: 60px;
      }

      a.app-inactive {
        border-bottom: 6px solid transparent;
      }

      a.app-active {
        font-size: .85rem;
      }
    }

    .anticon-safety-certificate {
      color: #fff;
    }
  }

  .header-right {
    padding-right: 5px;
  }

  .header-icon {
    display: inline-block;
    height: $l_header_height;
    line-height: $l_header_height;
    padding: 0 14px;
    float: left;
    @media only screen and (min-width: $l_screen_md_min) {
      padding: 0 18px;
    }
  }

  .app-sidebar-toggler {
    &:hover {
      cursor: pointer;
    }
  }

  .ant-menu-horizontal {
    line-height: ($l_header_height - 2); // 2px border
    border-color: transparent;
    float: left;
    position: relative;
    z-index: 1000;

    .ant-menu-submenu, .ant-menu-item {
      border-bottom: 0;
    }

    .ant-menu-submenu-title {
      @media(max-width: 1000px) {
        font-size: 12px;
        max-width: 110px;
        padding-right: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  .logo-img {
    float: left;
    display: block;
    width: 64px;
    height: 64px;
    padding: 4px;

    .st0 {
      fill: #f2f2f2
    }

    .st1 {
      opacity: .9
    }
  }

  #logo-img {
    .master {
      stroke: black;
    }

    .helper {
      stroke: white;
    }
  }

  .gradient1 {
    background: linear-gradient(to right, #1488cc, #2b32b2)
  }

  .gradient2 {
    background: linear-gradient(to right, #363795, #005C97)
  }

  .gradient3 {
    background: linear-gradient(to top, #021B79, #0575E6)
  }

}
