// mixin
@mixin button-icon-variant($size) {
  height: $size;
  width: $size;
  line-height: $size+12px;
  i {
    line-height: $size;
  }
  &.btn-icon-lined {
    line-height: ($size - 4px);

    i {
      line-height: ($size - 4px);
    }

    &.btn-icon-thin {
      line-height: ($size - 2px);

      i {
        line-height: ($size - 2px);
      }
    }
  }
}

@mixin social-button-variant($background, $border) {
  color: $white;
  background-color: $background;
  border-color: $border;
  &:hover,
  &:focus,
  &:active {
    color: $white;
    text-decoration: none;
    background-color: darken($background, 8%);
    border-color: darken($border, 12%)
  }
}

// make sure it overrides material2 styles
a,
button {
  &.btn-w-xs {
    min-width: 80px;
  }

  &.btn-w-sm {
    min-width: 100px;
  }

  &.btn-w-md {
    min-width: 135px;
  }

  &.btn-w-lg {
    min-width: 160px;
  }

  &.btn-round {
    border-radius: 2em;
  }

  &.btn-gap {
    margin: 5px;
  }

  &.btn-gap-h {
    margin: 0 5px;
  }

  &.btn-gap-v {
    margin: 0 0 5px;
  }
}

.btn-sidebar-right {
  float: right;
  width: 50%;
  padding-left: 20px;
  color: white;
}

.btn-sidebar-left {
  float: left;
  width: 100%;
  background: black;
  padding-left: 1rem;
  color: white;
}

.toggle-all-btn {
  width: 10%;
}

.toggle-all-label {
  width: 90%;
}

.btn-icon {
  padding: 0;
  display: inline-block;
  text-align: center;
  border-radius: $border-radius;
  @include button-icon-variant(35px);
}

.btn-icon-round {
  border-radius: 50%;
}

.btn-icon-sm {
  @include button-icon-variant(30px);
}

.btn-icon-md {
  @include button-icon-variant(45px);
  font-size: 18px;
}

.btn-icon-lg {
  @include button-icon-variant(65px);
  font-size: 28px;
}

.btn-icon-lg-alt {
  @include button-icon-variant(70px);
}

.btn-icon-xl {
  @include button-icon-variant(80px);
}

.collapse-btn {
  float: right;
  position: relative;
  bottom: 26px;
  right: 15px;
  height: 14px;
}

.expand-btn {
  position: relative;
  left: 25px;
  top: 5px;
  height: 14px;
}
