@import "variables-layout";

html {
  height: 100%;
  // important! otherwise in "layout-boxed" mode, content > height 100% will
  background-color: $l_body_bg !important;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: $font-size-sm;
}

.full-height {
  height: 100% !important;
}

#app-main-layout {
  height: 100vh;
}
